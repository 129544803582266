import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const OeuvreSection = () => {
  const oeuvre = [
    {
      title: "Pâte à papier",
      image:
        "https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1681476681983?alt=media&token=06715af7-18f2-4453-9555-f55084d64922",
      lien: "/pate-a-papier",
    },
    {
      title: "Peinture sur toile",
      image:
        "https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1683341141428?alt=media&token=bf4d01dc-23dd-4abd-a322-f1b2c66e1adf",
      lien: "/peinture-sur-toile",
    },
    {
      title: "Aquarelle",
      image:
        "https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1680515190232?alt=media&token=0d27e786-07e4-49e9-a3aa-269fc380e48a",
      lien: "/aquarelles",
    },
    {
      title: "Peinture sur papier",
      image:
        "https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1680696641402?alt=media&token=6ba57058-9bcf-4ed9-bdc6-2add55e415db",
      lien: "/peintures-sur-papier",
    },
  ];
  return (
    <div className="mt-1 ">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -20 1440 320">
        <path
          fill="#45aeee"
          fillOpacity="1"
          d="M0,0L120,32C240,64,480,128,720,160C960,192,1200,192,1320,192L1440,192L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
      <div className="flex flex-wrap gap-2 shadow-xl place-content-around bg-primary p-10 ">
        <h1 className="myFont w-full text-6xl font-bold text-base-100">
          Mes Oeuvres
        </h1>
        {oeuvre.map((oeuvre, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="card w-80 h-96 bg-base-100 shadow-xl overflow-hidden cursor-pointer mt-10 mask mask-squircle"
          >
            <figure className="">
              <img src={oeuvre.image} alt="Shoes" className="w-full h-60 " />
            </figure>
            <div className="card-body items-center text-center pt-0">
              <h2 className="card-title">{oeuvre.title}</h2>
              <div className="card-actions">
                <Link to={oeuvre.lien} className="btn btn-primary">
                  Voir
                </Link>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290">
        <path
          fill="#45aeee"
          fillOpacity="1"
          d="M0,0L10.4,21.3C20.9,43,42,85,63,90.7C83.5,96,104,64,125,80C146.1,96,167,160,188,192C208.7,224,230,224,250,192C271.3,160,292,96,313,85.3C333.9,75,355,117,376,112C396.5,107,417,53,438,53.3C459.1,53,480,107,501,117.3C521.7,128,543,96,563,106.7C584.3,117,605,171,626,202.7C647,235,668,245,689,234.7C709.6,224,730,192,751,181.3C772.2,171,793,181,814,202.7C834.8,224,856,256,877,266.7C897.4,277,918,267,939,250.7C960,235,981,213,1002,192C1022.6,171,1043,149,1064,144C1085.2,139,1106,149,1127,144C1147.8,139,1169,117,1190,122.7C1210.4,128,1231,160,1252,149.3C1273,139,1294,85,1315,101.3C1335.7,117,1357,203,1377,245.3C1398.3,288,1419,288,1430,288L1440,288L1440,0L1429.6,0C1419.1,0,1398,0,1377,0C1356.5,0,1336,0,1315,0C1293.9,0,1273,0,1252,0C1231.3,0,1210,0,1190,0C1168.7,0,1148,0,1127,0C1106.1,0,1085,0,1064,0C1043.5,0,1023,0,1002,0C980.9,0,960,0,939,0C918.3,0,897,0,877,0C855.7,0,835,0,814,0C793,0,772,0,751,0C730.4,0,710,0,689,0C667.8,0,647,0,626,0C605.2,0,584,0,563,0C542.6,0,522,0,501,0C480,0,459,0,438,0C417.4,0,397,0,376,0C354.8,0,334,0,313,0C292.2,0,271,0,250,0C229.6,0,209,0,188,0C167,0,146,0,125,0C104.3,0,83,0,63,0C41.7,0,21,0,10,0L0,0Z"
        ></path>
      </svg>
    </div>
  );
};
OeuvreSection.propTypes = {};

OeuvreSection.defaultProps = {};

export default OeuvreSection;
