import React from 'react';


const Footer = () => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280">
      <path fill="#1a1a1a" fillOpacity="1" d="M0,0L10,16C20,32,40,64,60,90.7C80,117,100,139,120,160C140,181,160,203,180,186.7C200,171,220,117,240,96C260,75,280,85,300,96C320,107,340,117,360,128C380,139,400,149,420,149.3C440,149,460,139,480,117.3C500,96,520,64,540,48C560,32,580,32,600,53.3C620,75,640,117,660,128C680,139,700,117,720,112C740,107,760,117,780,106.7C800,96,820,64,840,69.3C860,75,880,117,900,122.7C920,128,940,96,960,74.7C980,53,1000,43,1020,80C1040,117,1060,203,1080,240C1100,277,1120,267,1140,229.3C1160,192,1180,128,1200,122.7C1220,117,1240,171,1260,170.7C1280,171,1300,117,1320,85.3C1340,53,1360,43,1380,42.7C1400,43,1420,53,1430,58.7L1440,64L1440,320L1430,320C1420,320,1400,320,1380,320C1360,320,1340,320,1320,320C1300,320,1280,320,1260,320C1240,320,1220,320,1200,320C1180,320,1160,320,1140,320C1120,320,1100,320,1080,320C1060,320,1040,320,1020,320C1000,320,980,320,960,320C940,320,920,320,900,320C880,320,860,320,840,320C820,320,800,320,780,320C760,320,740,320,720,320C700,320,680,320,660,320C640,320,620,320,600,320C580,320,560,320,540,320C520,320,500,320,480,320C460,320,440,320,420,320C400,320,380,320,360,320C340,320,320,320,300,320C280,320,260,320,240,320C220,320,200,320,180,320C160,320,140,320,120,320C100,320,80,320,60,320C40,320,20,320,10,320L0,320Z"></path>
    </svg>
    <footer className="footer footer-center p-10 bg-neutral text-neutral-content">
      <div>
        <h1 className='myFont text-6xl'>Alain Lamy</h1>
      </div>
      <div>
        <div className="grid grid-flow-col gap-4">
          <p>Copyright © 2023 - All right reserved - Site web réaliser par <a href='https://www.linkedin.com/in/andr%C3%A9as-arnolfo-332101153/' className='link'>Andréas Arnolfo</a></p>
        </div>
      </div>
    </footer>
  </>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
