import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import Banner from '../banner/banner';
import Card from '../card/card';

const Vrac = () => {
   const db = getDatabase();
   const [articles, setArticles] = useState([]);

   useEffect(() => {
      const articlesRef = ref(db, 'articles/Photos en vrac/');

      onValue(articlesRef, (snapshot) => {
         const data = snapshot.val();

         const articlesList = Object.values(data).map((article) => ({
            title: article.title,
            subtitle: article.subtitle,
            image: article.image,
            description: article.description,
            dimensions: article.dimensions
         }));

         //ordonner les articles par nombre a la fin du titre
         const sortArticle = articlesList.sort((a, b) => {
            const aNumber = b.title.match(/\d+$/);
            const bNumber = a.title.match(/\d+$/);
            return aNumber - bNumber;
         });

         setArticles(sortArticle);
      });
   }, []);
   return (
      <div className='relative'>
      <Banner title={'Photos en vrac'} imgx={`url("https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/happeningsanstitre8.jpg?alt=media&token=fb8da8de-52ba-4550-a340-d64f67892a16")`}/>

      <div className='grid grid-cols-1 gap-10 md:grid-cols-4 mt-10 mr-5 ml-5'>
        {articles.map((item) => (
          <Card key={item.title} data={item} />
        ))}
      </div>
    </div>
   );
}

Vrac.propTypes = {};

Vrac.defaultProps = {};

export default Vrac;
