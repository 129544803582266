import React from "react";

//import all i need for delete item from firebase
import { getDatabase, ref, remove } from "firebase/database";
import { useForm } from "react-hook-form";

const Delete = () => {
  const db = getDatabase();
  //get title from form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  //get title from form
  const onSubmit = (data) => {
    console.log(data);
    //delete item from firebase
    remove(ref(db, `articles/${data.type}/undefined${data.title}`));
    remove(ref(db, `articles/${data.type}/${data.title}`));
  }; 
  return (
    <div>
      <form
        className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="mt-10 myFont text-6xl">Supprimer</h1>

        <div className="card-body">
        <label className="label">
          <span className="label-text">Type d'oeuvre</span>
        </label>
        <select
          className="select select-bordered w-full max-w-xs"
          {...register("type")}
        >
          <option>Aquarelles</option>
          <option>Peinture sur Toile</option>
          <option>Pâte à papier</option>
          <option>Peintures sur papier</option>
          <option>Photos en vrac</option>
          <option>ateliers</option>
        </select>
        <input
          className="input input-bordered mt-5 mb-5"
          type="text"
          name="title"
          placeholder="Numero"
          {...register("title")}
        />

        <button className="btn btn-danger" type="submit">Supprimer</button>
        </div>
      </form>
    </div>
  );
};

Delete.propTypes = {};

Delete.defaultProps = {};

export default Delete;
