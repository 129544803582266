import React from "react";
import PropTypes from "prop-types";

const Expositions = () => (
  <div className="mt-10 md:-mt-20">
    <h1 className="myFont text-6xl">Principales expositions et événements</h1>
    <p className="mt-10 mr-10 ml-10">
      Salon de Maromme / Salon de Rouen / Salon d'Elbeuf / Exposition
      personnelle Évreux / « Made in Normandy 1 » Saint Germain des Angles / «
      Made in Normandy 2 » Lyons la Forêt / Salon d'art contemporain Les Damps /
      « Les hivernales » Bonsecours / Exposition personnelle Théâtre du Moulin
      d'Andé / Salon « Créa » Saint Ouen de Thouberville / Exposition
      personnelle Médiathèque de Vernon / Réalisation de 700 volumes en
      polystyrène flottant Évreux / Créations avec un artiste Béninois en
      résidence Évreux / Exposition personnelle Évreux / Premier atelier
      d'artiste mis à disposition Évreux / Réalisation de grandes fresques et
      création d'ateliers artistiques Évreux / Intervenant artistique auprès de
      jeunes à Djilan au Kosovo sous l'égide de l'Unesco / Expositions
      collectives et personnelles Montpellier / Nouvelles tendances Marly le Roy
      / Exposition personnelle Domaine de Rouville Alizay / Salon international
      Nantes / Exposition personnelle Atelier Armand Paris / « L'année du Japon
      en France » Le toit de la Grande Arche Paris / Biennale de sculptures
      Centre d'Art contemporain Briançon / Festival européen d'art actuel
      Argentan / Exposition personnelle Carpentras / 50ème anniversaire des
      droits de l'homme Blois / Exposition personnelle Librairie Thuard Le Mans
      / Salon d'art contemporain Saint Sébastien sur Loire / « L'année de la
      France au Japon » Yokohama Japon / Galerie à part Paris / Biennale de
      Nantes / Salon d'art contemporain Palais d'Affari Florence Italie /
      Biennale de sculpture Centre d'Art Contemporain Briançon / Salon
      international de Béziers / Salon international de Bourges / Salon
      international d'art contemporain de Chouzy / Salon de Grand Quevilly /
      Galerie « Les Damiers » La Roche Guillon / Exposition personnelle « Week
      Art » Le Mans / Biennale du noir et blanc Paris / Les arts d'août
      Longueville sur Scie / Biennale de Conches / Exposition collective École
      d'architecture Paris / Salon de Servaville Salmonville / Regard sur la
      création contemporaine Saint Martin d'Aubigny
    </p>
  </div>
);

Expositions.propTypes = {};

Expositions.defaultProps = {};

export default Expositions;
