import React from 'react';
import styles from './home.module.css';
import Hero from '../hero/hero';
import Contact from '../contact/contact';
import OeuvreSection from '../oeuvreSection/oeuvreSection';
import Presse from '../presse/presse';
import About from '../about/about';
import { Route, Routes } from 'react-router-dom';
import Login from '../login/login';
import Sectiondeux from '../sectiondeux/sectiondeux';
import Expositions from '../expositions/expositions';
import Livres from '../livres/livres';

const Home = () => (
  <div className={styles.Home}>
    <Hero />
    <About />
    <OeuvreSection />
    <Livres/>
    <Sectiondeux />
    <Expositions />
    <Contact />
  </div>
);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
