import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDEqiDAZUh-kGLFpJa3Vb4g8aNxaA8SDQ4",
  authDomain: "alain-18a2c.firebaseapp.com",
  projectId: "alain-18a2c",
  storageBucket: "alain-18a2c.appspot.com",
  messagingSenderId: "1007707401729",
  appId: "1:1007707401729:web:457a463646437b9fa25a00",
  measurementId: "G-9Q6PYX6TBE",
  databaseURL: "https://alain-18a2c-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app, 'gs://alain-18a2c.appspot.com');


export {database}
export {storage}
export default app;