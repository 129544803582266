import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";
import Card from '../card/card';
import Banner from '../banner/banner';

const Patepapier = () => {
  const db = getDatabase();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const articlesRef = ref(db, 'articles/Pâte à papier/');

    onValue(articlesRef, (snapshot) => {
      const data = snapshot.val();

      const articlesList = Object.values(data).map((article) => ({
        title: article.title,
        subtitle: article.subtitle,
        image: article.image,
        description: article.description,
        dimensions: article.dimensions
      }));

      //ordonner les articles par nombre dans subtitle
      const sortArticle = articlesList.sort((a, b) => {
        const aNumber = b.subtitle.match(/\d+$/);
        const bNumber = a.subtitle.match(/\d+$/);
        return aNumber - bNumber;
      });

      setArticles(sortArticle);

    });
  }, []);

  return (
    <div>
      <Banner title={'Pâte à papier'} imgx={`url("https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1680695853980?alt=media&token=6571da7e-bacf-4a66-8732-fe013492761e")`}/>
      <div className='grid grid-cols-1 gap-10 md:grid-cols-4 mt-10 mr-5 ml-5'>
        {articles.map((item) => (
          <Card key={item.subtitle} data={item} />
        ))}
      </div>
    </div>

  );
};

Patepapier.propTypes = {};

Patepapier.defaultProps = {};

export default Patepapier;
