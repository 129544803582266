import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import Banner from '../banner/banner';
import Card from '../card/card';


const Aquarelles = (props) => {

  const db = getDatabase();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const articlesRef = ref(db, 'articles/Peinture sur Toile/');

    onValue(articlesRef, (snapshot) => {
      const data = snapshot.val();
      // i need to filter the data to only get the ones with type === 'Pâte à papier'

      const articlesList = Object.values(data).map((article) => ({
        title: article.title,
        subtitle: article.subtitle,
        image: article.image,
        years: article.years,
        description: article.description,
        dimensions: article.dimensions
      }));


      setArticles(articlesList);
    });
  }, []);



  const uniqueYears = [...new Set(articles.map((item) => item.years))];

  const trieUniqueYears = uniqueYears.sort((a, b) => b - a);




  return (
    <div className='relative'>
      <Banner title={'Peinture sur Toile'} imgx={`url("https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1683341141428?alt=media&token=bf4d01dc-23dd-4abd-a322-f1b2c66e1adf")`}/>

      {trieUniqueYears.map((year) => (
        <div tabIndex={0} className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
          <div className="collapse-title text-xl font-medium">
            {year}
          </div>
          <div className="collapse-content">
            <div className='grid grid-cols-1 gap-10 md:grid-cols-4 mt-10 mr-5 ml-5'>
              {articles.filter((item) => item.years === year).map((item, index) => (
                <Card key={item.subtitle} data={item} />
              ))} 
            </div>
          </div>
        </div>
      ))}

      {/* <div className='grid grid-cols-1 gap-10 md:grid-cols-4 mt-10 mr-5 ml-5'>
        {articles.map((item) => (
          <Card key={item.title} data={item} />
        ))}
      </div> */}
    </div>
  );
}

Aquarelles.propTypes = {};

Aquarelles.defaultProps = {};

export default Aquarelles;
