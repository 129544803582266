import React, { useState, useEffect, useRef } from 'react';
import './card.css';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { motion, useDomEvent } from "framer-motion";


/**
 * Returns a card component with a dynamic image and text content.
 * @param {object} props - The props object.
 * @param {string} props.data.image - The image URL for the card.
 * @param {string} props.data.title - The title for the card.
 * @param {string} props.data.subtitle - The subtitle for the card.
 * @param {string} props.data.dimensions - The dimensions for the card.
 * @param {string} props.data.description - The description for the card.
 * @returns {JSX.Element} A card component.
 */
const Card = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const storage = getStorage();
  const imageRef = ref(storage, props.data.image);
  const id = imageUrl.match(/(?<=o\/)(.*?)(?=\?)/);


  useEffect(() => {
    getDownloadURL(imageRef).then((url) => {
      setImageUrl(url);
    }).catch((error) => {
      console.log(error);
    });
  }, []);
  // if url of the site contain ateliers dont show card !


  const myTitle = props.data.title;
  //remove number and special charatere from title
  const title = myTitle.replace(/[0-9]/g, "");
  const [isOpen, setOpen] = useState(false);
  useDomEvent(useRef(window), "scroll", () => isOpen && setOpen(false));

  const transition = {
    type: "spring",
    damping: 25,
    stiffness: 120
  };

  const myNumber = props.data.title.match(/\d+/g);

 
  return (
    <div className={`image-container ${isOpen ? "open" : ""}`} >
      <motion.div
        animate={{ opacity: isOpen ? 1 : 0 }}
        transition={transition}
        className="shade transition-all duration-1000 flex flex-col gap-8  md:flex-row md:justify-center md:items-end"
        onClick={() => setOpen(false)}
      >
        <motion.h2 className="text-3xl text-primary mb-6">{title}</motion.h2>
        <motion.h3 className="text-3xl text-primary mb-6">numéro : <strong className='text-secondary'>{props.data.subtitle}</strong></motion.h3>
        {props.type === "atelier" ? ''
         :
        <motion.h3 className="text-3xl text-primary mb-6">dimensions : <strong className='text-secondary'>{props.data.dimensions}</strong></motion.h3>
        }
        
        <motion.p className="card-text text-primary">{props.data.description}</motion.p>
      </motion.div>
      <motion.div
        layoutId={id}
        onClick={() => setOpen(!isOpen)}
        layoutTransition={transition}
        className="card bg-transparent shadow-xl cursor-pointer z-5 relative">
        <motion.figure className='h-48 md:h-96'>
          <motion.img src={imageUrl} alt={props.data.title} />
        </motion.figure>
        {props.type === "atelier" ? '' :
        <motion.div className="z-50 card-title flex justify-between bottom-0 left-0 right-0 text-white bg-black pl-5 pr-5">
          <h1 className='font-thin text-sm'>{title}</h1>
          <p className='font-thin text-sm'>{myNumber ? myNumber : ''}</p>
        </motion.div>
        }
      </motion.div>
    </div>
  );
}

Card.propTypes = {};

Card.defaultProps = {};

export default Card;
