import React from 'react';
import PropTypes from 'prop-types';
import styles from './banner.module.css';
import { Fade } from 'react-awesome-reveal';

const Banner = (title) => {
  return ( 
  <div className="hero min-h-screen bg-fixed" style={{ backgroundImage: title.imgx }}>
    <div className="hero-overlay bg-opacity-60"></div>
    <div className="hero-content text-center text-neutral-content">
      <div className="max-w-md">
        <Fade cascade duration={200} className="mb-5 text-5xl font-bold myFont md:text-7xl">{title.title}</Fade>
      </div>
    </div>
  </div>
);}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  imgx: PropTypes.string.isRequired,
};

Banner.defaultProps = {
  title: '',
  imgx: '',
};

export default Banner;
