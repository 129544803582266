import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './addArt.module.css';
import { getDatabase, ref, set } from "firebase/database";
import { useForm } from "react-hook-form";
import { getStorage, uploadBytes, ref as sRef } from "firebase/storage";
import { Navigate } from 'react-router-dom';
import Delete from '../delete/delete';
import Modify from '../modify/modify';



const AddArt = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [validate, setValidate] = useState(false);

  const db = getDatabase();
  const storage = getStorage();

  //get token from url 
  const url = window.location.href;
  const token = url.split('?')[1];
  console.log(token);

  // si le token n'est pas présent, on redirige vers la page login
  if (!token) {
    return <Navigate to='/Admin' />
  }

  const onSubmit = data => {
    console.log(data);
    let id = Date.now().toString();
    console.log(id);
    let storageRef = sRef(storage, id);
    setValidate(true);
  
    // Vérifiez s'il y a des erreurs dans les champs obligatoires
    if (errors.title || errors.subtitle || errors.years || errors.image) {
      return;
    }
  
    if (data.type === 'Peinture sur Toile') {
      set(ref(db, `articles/${data.type}/` + data.subtitle), {
        title: data.title,
        subtitle: data.subtitle,
        type: data.type,
        years: data.years,
        description: data.description,
        image: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/' + id,
        dimensions: data.dimensions,
      });
    } else {
      set(ref(db, `articles/${data.type}/${data.years}` + data.subtitle), {
        title: data.title,
        subtitle: data.subtitle,
        type: data.type,
        description: data.description,
        image: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/' + id,
        dimensions: data.dimensions,
      });
    }
  
    uploadBytes(storageRef, data.image[0])
  };



  return (
    <div className='flex justify-around items-center min-h-screen'>

      {validate ? <div className="toast toast-end">
        <div className="alert alert-info">
          <div>
            <span>Bien envoyer</span>
          </div>
        </div>
      </div> : ''}
      
      <form className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100" onSubmit={handleSubmit(onSubmit)}>
        <h1 className='myFont text-6xl'>Ajouter</h1>
        <div className="card-body">
          <label className="label">
            <span className="label-text">Type d'oeuvre</span>
          </label>
          <select className="select select-bordered w-full max-w-xs" {...register('type')}>
            <option>Aquarelles</option>
            <option>Peinture sur Toile</option>
            <option>Pâte à papier</option>
            <option>Peintures sur papier</option>
            <option>Photos en vrac</option>
            <option>ateliers</option>
          </select>
          {watch('type') === 'Peinture sur Toile' && (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Année</span>
              </label>
              <input type="number" placeholder="annees" className="input input-bordered" {...register('years', { required: true })} />
              {errors.years && <span className="text-red-500">L'année est obligatoire</span>}

            </div>
          )}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Titre (tu y mets ce que tu veux)</span>
            </label>
            <input type="text" placeholder="Titre" className="input input-bordered" {...register('title', { required: true })} />
            {errors.title && <span className="text-red-500">Le titre est obligatoire</span>}
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Numero </span>
            </label>
            <input type="text" placeholder="Numero" className="input input-bordered" {...register('subtitle', { required: true })} />
            {errors.subtitle && <span className="text-red-500">Le Numero est obligatoire</span>}
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Dimensions</span>
            </label>
            <input type="text" placeholder="Dimensions" className="input input-bordered" {...register('dimensions')} />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Photo</span>
            </label>
            <input type="file" className="file-input file-input-bordered w-full max-w-xs" {...register('image', { required: true })} />
            {errors.image && <span className="text-red-500">L'image est obligatoire</span>}

          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Description</span>
            </label>
            <input type="text" placeholder="Description" className="input input-bordered" {...register('description')} />
          </div>
          <div className="form-control mt-6">
            <button type="submit" className="btn btn-accent">Valider</button>
          </div>
        </div>
      </form>
      <Delete />
    </div>

  );
}

AddArt.propTypes = {};

AddArt.defaultProps = {};

export default AddArt;
