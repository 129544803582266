import React from 'react';

const Presse = () => {
  const d = [
    { id: 1, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/ARTICLE%20JARDINS%202024.jpg?alt=media&token=92c4a848-85cb-4479-8588-b07934bff38e' },
    { id: 2, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/2009.jpg?alt=media&token=2d4ff769-1914-4348-ade1-c30d398faddf' },
    { id: 3, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/27%20octobre%202020.jpg?alt=media&token=2e90a334-d2d9-4ae3-a7f2-73c055477e58' },
    { id: 4, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Avril%202016.jpg?alt=media&token=02921c46-6ef6-4804-b55d-7cae520c2bb1' },
    { id: 5, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/D%C3%A9cembre%202018.jpg?alt=media&token=3542c0aa-e853-45c9-b2bd-f00402417bb8' },
    { id: 6, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/presse%201.jpg?alt=media&token=0bcc2ebb-517e-45c7-a0e9-8175486dc789' },
    { id: 7, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/presse%204.jpg?alt=media&token=75e58793-2e56-43c9-bdbd-7e770360539c' },
    { id: 8, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/D%C3%A9peche%20Janvier%202016.jpg?alt=media&token=7ded373b-b072-49fb-8209-5b9ebc76413c' },
    { id: 9, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Eure%20info%202005.jpg?alt=media&token=66b6cff3-4142-498d-9462-1e3c57ed77d9' },
    { id: 10, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Eure_info%20semaine%20du%2022%20au%2024%20avril%202024.jpg?alt=media&token=049b6d76-ff5a-4ac6-92ec-57ff6bbfb8eb' },
    { id: 11, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Eure_info_2007.jpg?alt=media&token=92e354df-f5db-49f4-9c2f-479a0126a05b' },
    { id: 12, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/La%20depeche%202007.jpg?alt=media&token=c6352f26-1a62-4eaf-998c-c953d658dae4' },
    { id: 13, img: "https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Le%20courrier%20de%20l'eure_2009.jpg?alt=media&token=791f85bc-b8ed-4797-9c6e-42598505ec79" },
    { id: 14, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Mai%202016.jpg?alt=media&token=00e983a1-e0f9-4519-9e2d-83b54d39c58e' },
    { id: 15, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Paris%20Normandie%202005.jpg?alt=media&token=66c32317-e8ed-46af-a677-90f023673cff' },
    { id: 16, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/Paris%20Normandie%202006.jpg?alt=media&token=904e5ee7-0ddf-4b43-97aa-b9ef565c44af' },
    { id: 17, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/acryliquesurpateapapierMidi%20Libre%202000.jpg?alt=media&token=d425adcb-44e5-404a-9d58-94ee3c6285a3' },
    { id: 18, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/alacharite%20mars%201998.jpg?alt=media&token=731a50e3-a4e5-49e4-a8d7-fd98bb7e1926' },
    { id: 19, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/alaconquetedelalumiere%20PN%202005.jpg?alt=media&token=559ef5a8-0f51-4723-819d-aa67833ef9bc' },
    { id: 20, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/alainaupaysdusoleillevantmars%201997.jpg?alt=media&token=256e1e3a-475a-4fff-81ed-dfb7af75bc1f' },
    { id: 21, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/artbarDecembre%202000.jpg?alt=media&token=a7954fd1-a2e0-443d-9741-b57e56e192ca' },
    { id: 22, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/article11994.jpg?alt=media&token=10211deb-5a48-4b01-a1f6-d7baf714deb9' },
    { id: 23, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/article2pnfev93.jpg?alt=media&token=7f1fa9d8-f23e-4109-b7e5-1b265c443252' },
    { id: 24, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/article33revueartpictorial1Japon%201998.jpg?alt=media&token=68c90670-a0cc-43a2-8451-31562fbab353' },
    { id: 25, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/article3eureinter1994.jpg?alt=media&token=1be0f0b2-3dc3-43a3-8aee-bd376a4c5967' },
    { id: 26, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/citartevreux_2008.jpg?alt=media&token=2c5e65bf-ca73-426c-a773-79b6b4b0f1ca' },
    { id: 27, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/commeunpoisson_2008.jpg?alt=media&token=6ced8808-400b-4fac-8d2b-8a11ac73086f' },
    { id: 28, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/editionanglaise1998.jpg?alt=media&token=9ee56f8f-2f67-4626-9b5c-8a8d80d51c1b' },
    { id: 29, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/juillet%202018.jpg?alt=media&token=2d537739-69df-4fbb-a3fa-80d637ee5656' },
    { id: 30, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/lapeinture....maispasquef%C3%A9vrier%202011.jpg?alt=media&token=b561235b-9d55-4277-ad1e-af31c265c966' },
    { id: 31, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/lartdumasculinplurielmars%201998.jpg?alt=media&token=3f98f8f6-890c-4846-be52-17cfbc254788' },
    { id: 32, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/maisonsnormandesconches1994.jpg?alt=media&token=ea919009-3082-49a7-84dc-caac3c9bb38b' },
    { id: 33, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/montpellier%202000.jpg?alt=media&token=e0eafae3-8adb-43b2-8d66-14932a78dc72' },
    { id: 34, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/ouvertureatelierdepeinture%20PN%202004.jpg?alt=media&token=c3bdfe67-996e-4ef4-9a8e-adba9bbf9477' },
    { id: 35, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/pateapapier%20mars%201998.jpg?alt=media&token=acb7d3ce-98e2-4eed-8579-0ebef701c7b2' },
    { id: 36, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/regardsdartistes_2008.jpg?alt=media&token=57915b88-425f-4f38-af9d-4c8511dbd1c0' },
    { id: 37, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/vitrinealapage1997.jpg?alt=media&token=d75b2624-05a6-45ac-970a-1b6eb21d3ab1' },
    { id: 38, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/weekartouestfrance1997.jpg?alt=media&token=fa14f953-5ff7-4c01-914e-55b145826106' },
    { id: 39, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/presse%203%20bis.jpg?alt=media&token=ac95e780-909f-4b08-aaaf-408b0793d11d' },
    { id: 40, img: 'https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/presse%202.jpg?alt=media&token=c77daf19-4c13-4180-96a7-2204e56d6974' },
  ]

  return (
    <section className='mt-10'>
      <h1 className='myFont text-6xl'>Articles de presse</h1>
      <div className='carousel w-full m-auto mt-10 '>
        {d.map((item, index) => {
          return (
            <div key={index} id={`slide${item.id}`} className="carousel-item relative w-full m-auto">
              <img src={item.img} alt="" className=' h-96 md:h-128 m-auto' />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                {item.id === 1 ? <a href={`/presse/#slide40`} className="btn btn-circle">❮</a> : <a href={`/presse/#slide${item.id - 1}`} className="btn btn-circle">❮</a>}
                {item.id === 38 ? <a href={`/presse/#slide2`} className="btn btn-circle">❯</a> : <a href={`/presse/#slide${item.id + 1}`} className="btn btn-circle">❯</a>}
              </div>
            </div>
          )
        })}
      </div>
    </section >
  );
}

Presse.propTypes = {};

Presse.defaultProps = {};

export default Presse;
