import React from 'react';
import PropTypes from 'prop-types';
import styles from './navbar.module.css';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <div className="navbar bg-base-100 flex-wrap">
    <div className="m-auto md:flex-1">
      <Link to='/' className="btn btn-ghost normal-case text-3xl myFont">Alain Lamy</Link>
    </div>
    <div className="navbar-center hidden md:flex">
      <Link className='btn btn-ghost' to='/'>Accueil</Link>
    </div>
  </div>
);

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
