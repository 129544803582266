import './App.css';
// react-router-dom
import { Routes, Route } from 'react-router-dom';
// pages
import Login from './components/login/login';
import Home from './components/home/home';
import AddArt from './components/addArt/addArt';
import Patepapier from './components/patepapier/patepapier';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Aquarelles from './components/aquarelles/aquarelles';
import PeintureSurToile from './components/peintureSurToile/peintureSurToile';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Peinturesurpapier from './components/peinturesurpapier/peinturesurpapier';
import Vrac from './components/vrac/vrac';
import Ateliers from './components/ateliers/ateliers';
import Presse from './components/presse/presse';


function App() {

  document.title = "Alain Lamy";
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/admin' element={<Login />} />
        <Route path='/addArticle' element={<AddArt />} />
        <Route path='/pate-a-papier' element={<Patepapier />} />
        <Route path='/aquarelles' exact element={<Aquarelles />} />
        <Route path='/peinture-sur-toile' element={<PeintureSurToile />} />
        <Route path='/vrac' element={<Vrac />} />
        <Route path='/presse' element={<Presse />} />
        <Route path='/peintures-sur-papier' element={<Peinturesurpapier />} />
        <Route path='/ateliers' element={<Ateliers />} />
        <Route path='*' element={<h1>404: Not Found</h1>} />
      </Routes>
      <Footer />

    </div>
  );
}

export default App;
