import React, { useEffect, useState } from "react";

//import all i need for modify item from firebase
import { getDatabase, ref, set, onValue, off } from "firebase/database";
import { useForm } from "react-hook-form";

const Modify = () => {
   const db = getDatabase();
   const {
     register,
     handleSubmit,
     setValue,
     formState: { errors },
   } = useForm();
   const [currentItem, setCurrentItem] = useState(null);
   const [itemId, setItemId] = useState(null);
   const [type, setType] = useState(null);
 
   useEffect(() => {
     // Récupérer les données de l'item à modifier à partir de Firebase
     const itemRef = ref(db, `articles/${type}/undefined${itemId}`);
     onValue(itemRef, (snapshot) => {
       const item = snapshot.val();
       setCurrentItem(item);
 
       // Définir les valeurs actuelles des champs du formulaire
       setValue("type", item.type);
       setValue("title", item.title);
       setValue("subtitle", item.subtitle);
       setValue("image", item.image);
       setValue("description", item.description);
       setValue("dimensions", item.dimensions || ""); // Assurez-vous de gérer les cas où "dimensions" est null ou non défini
     });
 
     return () => {
       // Désabonner du snapshot lorsque le composant est démonté
       off(itemRef);
     };
   }, [db, itemId, setValue]);
 
   const onSubmit = (data) => {
     console.log(data);
 
     const newData = {
      ...(currentItem || {}), // Utilisez un objet vide {} comme valeur par défaut si currentItem est null
      type: data.type,
      title: data.title,
      subtitle: data.subtitle,
      image: data.image,
      description: data.description,
      dimensions: data.dimensions || null,
    };
 
     set(ref(db, `articles/${type}/${itemId}`), newData);
   };



  return (
    <div>
      <form
        className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="mt-10 myFont text-6xl">Modifier</h1>

        <div className="card-body">
          <label className="label">
            <span className="label-text">Type d'oeuvre</span>
          </label>
          <select
            className="select select-bordered w-full max-w-xs"
            {...register("type")}
            onChange={(e) => setType(e.target.value)}
          >
            <option>Aquarelles</option>
            <option>Peinture sur Toile</option>
            <option>Pâte à papier</option>
            <option>Peintures sur papier</option>
            <option>Photos en vrac</option>
            <option>ateliers</option>
          </select>
          <input
            className="input input-bordered mt-5 mb-5"
            type="text"
            name="title"
            placeholder="title"
            {...register("title")}
          />
          <input
            className="input input-bordered mt-5 mb-5"
            type="text"
            name="subtitle"
            placeholder="Numero"
            {...register("subtitle")}
            onChange={(e) => setItemId(e.target.value)}
          />
          <input
            className="input input-bordered mt-5 mb-5"
            type="text"
            name="image"
            placeholder="Image"
            {...register("image")}
          />
          <input
            className="input input-bordered mt-5 mb-5"
            type="text"
            name="description"
            placeholder="Description"
            {...register("description")}
          />
          <input
            className="input input-bordered mt-5 mb-5"
            type="text"
            name="dimensions"
            placeholder="Dimensions"
            {...register("dimensions")}
          />

          <button className="btn btn-primary" type="submit">
            Modifier
          </button>
        </div>
      </form>
    </div>
  );
};

Modify.propTypes = {};

Modify.defaultProps = {};

export default Modify;
