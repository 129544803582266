import React  from "react";
import { BsFacebook } from "react-icons/bs";
import { motion } from "framer-motion";

const Contact = () => {

  return (
    <div className="flex flex-wrap flex-col justify-center mt-20">
      {/* <h1 className="myFont text-5xl w-full">Me contacter</h1> */}
      <div className="card p-10 flex-col justify-around items-center  w-full m-auto mt-10 md:flex-row ">
        <div className="flex flex-col gap-5">
          <h1 className="myFont text-5xl font-bold ">Email</h1>
          <a href="mailto:san@antonio.net" className="text-2xl link">alainlamyartiste@orange.fr</a>
        </div>
        <div className="flex flex-col gap-5 mt-10 md:mt-0">
          <h1 className="myFont text-5xl font-bold">Téléphone</h1>
          <p className="text-2xl ">06 62 94 25 24</p>
        </div>
        <div  className="flex flex-col gap-5">
          <h1 className="myFont text-5xl font-bold mt-10">Réseaux</h1>
          <div className="flex flex-col gap-5 justify-between items-center">
            <motion.a
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              href="https://www.facebook.com/alainlamyartistepeintre"
            >
              <BsFacebook className="w-14 h-14 text-primary" />
            </motion.a>
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
