import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDatabase, ref, onValue } from 'firebase/database';
import Banner from '../banner/banner';
import Card from '../card/card';


const Peinturesurpapier = (props) => {
   const db = getDatabase();
   const [articles, setArticles] = useState([]);

   useEffect(() => {
      const articlesRef = ref(db, 'articles/Peintures sur papier/');

      onValue(articlesRef, (snapshot) => {
         const data = snapshot.val();

         const articlesList = Object.values(data).map((article) => ({
            title: article.title,
            subtitle: article.subtitle,
            image: article.image,
            description: article.description,
            dimensions: article.dimensions
         }));

         setArticles(articlesList);
      });
   }, []);
   return (
      <div className='relative'>
         <Banner title={'Peinture sur papier'} imgx={`url("https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1680696641402?alt=media&token=6ba57058-9bcf-4ed9-bdc6-2add55e415db")`}/>

         <div className='grid grid-cols-1 gap-10 md:grid-cols-4 mt-10 mr-5 ml-5'>
            {articles.map((item) => (
               <Card key={item.title} data={item} />
            ))}
         </div>
      </div>
   );
}

Peinturesurpapier.propTypes = {};

Peinturesurpapier.defaultProps = {};

export default Peinturesurpapier;
