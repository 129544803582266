import React from 'react';
import PropTypes from 'prop-types';

const About = () => (
   <section className='mt-10'>
      <h1 className='myFont text-6xl'>L'intemporalité de la vie</h1>
      <p className='w-3/4 text-center m-auto mb-20 mt-20 md:mb-10 text-xl'>Alain Lamy est un artiste peintre Normand. Il tire son inspiration essentiellement de la
         nature. Sensible au contexte environnemental, ses peintures quelles soient abstraites ou
         figuratives sont source d'espoir et de quiétude. Les arbres symboles de la vie sont
         omniprésents dans ses œuvres. A chacune de ses créations, entre les formes et les
         couleurs, l'artiste crée de nouveaux espaces. <br /> « L'intemporalité de la vie » est un titre
         générique tiré du livre « Le Prophète » de Khalil Gibran poète et peintre libanais.</p>
   </section>
);

About.propTypes = {};

About.defaultProps = {};

export default About;
