import React from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";

const Livres = () => (
  <div>
    <h1 className="myFont text-6xl">Mes livres</h1>
    <div className="m-auto w-3/4 mt-10">
      <YouTube
        videoId="NhwluDovY6o"
        iframeClassName={"w-full h-100 rounded-3xl "}
      />
    </div>
    <h2 className="myFont text-3xl mt-5">"déconfiné" - 2021</h2>
    <p>
      Les deux artistes peintres Gu Lagalerie et Alain Lamy ont décidé de
      regrouper dans un livre quelques-unes de leurs œuvres réalisées lors des
      confinements, près d'Évreux (Eure).
    </p>

    <div className="flex flex-wrap flex-col justify-center mt-10">
      <img
        className="w-96 m-auto"
        src="https://fr.shopping.rakuten.com/photo/612040334.jpg"
        alt="livre couverture"
      />
      <h2 className="myFont text-3xl mt-5">"Révolte à l'encre de Chine" - 1993</h2>

      <p></p>
    </div>
  </div>
);

Livres.propTypes = {};

Livres.defaultProps = {};

export default Livres;
