import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './login.module.css';
import { useForm } from "react-hook-form";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { NavLink, useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = data => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const accessToken = user.accessToken.slice(0, 24);
        const thisUrl = `/addArticle?token=${accessToken}`;
        navigate(thisUrl)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });
  };


  return (
    <div className='flex flex-col justify-center items-center min-h-screen'>
      <h1 className='text-2xl mb-10'>Connection</h1>

      <form className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="form-control">
            <label className="label">
              <span className="label-text">Identifiant</span>
            </label>
            <input type="text" placeholder="Identifiant" className="input input-bordered" {...register("email")} />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Mot de passe</span>
            </label>
            <input type="text" placeholder="Mot de passe" className="input input-bordered" {...register("password")} />
          </div>
          <div className="form-control mt-6">
            <button type="submit" className="btn btn-primary">Se Connecter</button>
          </div>
        </div>
      </form>
    </div>
  );
}
// Login.propTypes = {};

// Login.defaultProps = {};

export default Login;
