import React from 'react';
import { Fade } from 'react-awesome-reveal';

const Hero = () => (
  <div className="hero min-h-screen bg-fixed justify-end" style={{ backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/310963856_5495053100532370_4343353308549540811_n.jpg?alt=media&token=3b4515fa-1faa-48d5-b5a4-84c9e3d67731")` }}>
    <div className="hero-overlay bg-opacity-60 w-full md:rounded-3xl"></div>
    <div className="hero-content text-center text-neutral-content">
      <div className="max-w-md">
        <Fade className="mb-20 text-7xl font-bold myFont titleHero">Alain Lamy</Fade>
        <p className="mb-5 text-2xl">Artiste Peintre <br />
          Intervenant intergénérationnel</p>
      </div>
    </div>
  </div>
);

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
