import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getDatabase, ref, onValue } from "firebase/database";
import Banner from "../banner/banner";
import Card from "../card/card";

const Ateliers = () => {
  const db = getDatabase();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const articlesRef = ref(db, "articles/ateliers/");

    onValue(articlesRef, (snapshot) => {
      const data = snapshot.val();

      const articlesList = Object.values(data).map((article) => ({
        title: article.title,
        subtitle: article.subtitle,
        image: article.image,
        description: article.description,
        dimensions: article.dimensions,
      }));

      //ordonner les articles par nombre a la fin du titre
      const sortArticle = articlesList.sort((a, b) => {
        const aNumber = b.subtitle.match(/\d+$/);
        const bNumber = a.subtitle.match(/\d+$/);
        return aNumber - bNumber;
      });

      setArticles(sortArticle);
    });
  }, []);
  return (
    <div className="relative">
      <Banner
        title={"Interventions"}
        imgx={`url("https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/1681742234503?alt=media&token=4a327410-d82f-45f3-8521-0589807f869e")`}
      />

      <div className="grid grid-cols-1 gap-10 md:grid-cols-4 mt-10 mr-5 ml-5">
        {articles.map((item) => (
          <Card key={item.title} data={item} type='atelier'/>
        ))}
      </div>
    </div>
  );
};

Ateliers.propTypes = {};

Ateliers.defaultProps = {};

export default Ateliers;
